<template>
	<div id="cashbackList" class="el-content">
		<router-link to="/marketing/cashback/edit">
			<a-button v-has="{action:'/marketing/cashback/edit'}" type="primary">
				<i class="ri-add-line ri-top"></i>
				添加返现活动
			</a-button>
		</router-link>
		<a-table :pagination="false" :data-source="info.list" row-key="id" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'商品信息',dataIndex:'goods',slots:{customRender:'goods'}},
			{title:'商品类型',dataIndex:'good_type',slots:{customRender:'good_type'}},
			{title:'返现周期',dataIndex:'cash_type',slots:{customRender:'cash_type'}},
			{title:'上下架',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
			{title:'返现次数(次)',dataIndex:'end_time'},
			{title:'返现金额(元)',dataIndex:'money'},
			{title:'创建时间',dataIndex:'create_time'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #goods="{record}">
				<div style="display: flex;">
					<a-image style="width: 50px;height: 50px;" :src="record.goods.cover" ></a-image>
					<span v-if="record.good_type == 1">{{record.goods.goods_name}}</span>
					<span v-else-if="record.good_type == 2">{{record.goods.land_name}}</span>
					<span v-else>{{record.goods.name}}</span>
				</div>
			</template>
			<template #good_type="{record}">
				<a-tag color="#FF0066">{{goodsType(record.good_type)}}</a-tag>
			</template>
			<template #cash_type="{record}">
				<a-tag color="#6699FF">{{cashType(record.cash_type)}}</a-tag>
			</template>
			<template #is_put_away="{record}">
				<a-tag color="#33CC66" v-if="record.is_put_away==1">上架中</a-tag>
				<a-tag color="#999" v-else>已下架</a-tag>
			</template>
			<template #action="{record}">
				<a-space>
					<router-link :to="{path:'/marketing/cashback/edit',query:{id:record.id}}">
						<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'/marketing/cashback/edit'}"></kd-button>
					</router-link>
					<router-link :to="{path:'/marketing/cashback/fund_log',query:{id:record.id}}">
						<kd-button type="success" 
							title="查看资金日志" 
							icon="ri-file-list-3-line" 
							v-has="{action:'/marketing/cashback/fund_log'}">
						</kd-button>
					</router-link>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_marketing_cashback_del'}"  @click="deleteBackcash(record.id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getCashList(info.page,e)}"
				@change="(e)=>{getCashList(e,info.limit)}"
			/>
		</div>
	</div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
import cashbackModel from '@/api/addons/cashback'
import commonModel from '@/api/common'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
		})
		const goodsType = computed(()=>{
			return function(e){
				return e == 1 ? '商品':(e==2 ? '土地' :'认养')
			}
		})
		const cashType = computed(()=>{
			return function(e){
				return e == 1 ? '一次性返现':(e==2 ? '每天返现' :'每周返现')
			} 
		})
		getCashList(1,state.info.limit)
		function getCashList(page,limit){
			cashbackModel.getCashBack(page,limit,null,res=>state.info = {limit,...res})
		}

		const deleteBackcash = (id)=>commonModel.deleteDataList(id,41,"确认要删除该条返现活动信息吗？").then(()=>{
			getCashList(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			goodsType,
			cashType,
			getCashList,
			deleteBackcash
		}
	}
}
</script>

<style>
</style>
